import React, { useEffect, useReducer } from "react";
import { Box, Alert, AlertTitle, Tooltip, IconButton, Switch, FormControlLabel, TextField } from "@mui/material";
import { getSession } from "../../../auth.js";
import api from "../../../api";
import Loading from "../../Loading/index.js";
import LoadingButton from "@mui/lab/LoadingButton";
import AddScoutForm from "./AddScoutForm/index.js";
import ConnectModal from "./ConnectModal/index.js";
import useNotification from "../../Notification";

import DeleteIcon from '@mui/icons-material/Delete';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import ConfirmationDialogCaptcha from '../../Dialogs/ConfimationDialogCaptcha'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  gridClasses,
  ptBR
} from '@mui/x-data-grid-pro';
import { ptBR as corePtBR } from '@mui/material/locale';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider
} from '@mui/material/styles';
import defaultReducer from "../../../utils/defaultReducer.js";
import HistoryIcon from '@mui/icons-material/History';
import VoiceOverOffRoundedIcon from '@mui/icons-material/VoiceOverOffRounded';
import History from "./History/index.js";
import IgnoreList from "./IgnoreList/index.js";
import EmailIcon from '@mui/icons-material/Email';
import { validateEmail } from '../../Report/NewDetailedRegister/report_export_data'
import { EmailTextField } from "./EmailDialogs.js/index.js";

let variable_enterprise = null

const status_dict = {
  'open': (<span style={{ color: 'green' }}>Conectado</span>),
  'connecting': (<span style={{ color: 'orange' }}>Conectando</span>),
  'close': (<span style={{ color: 'red' }}>Desconectado</span>)
}

const theme = createTheme({
  palette: {
    primary: { main: '#21446C' },
  },
}, ptBR, corePtBR);


const defColumns = (state, setState) => {
  const columns = []
  columns.push({ field: 'name', headerName: 'Nome', flex: 1 })
  columns.push({ field: 'phone_number', headerName: 'Telefone', flex: 1 })
  columns.push({
    field: 'status', headerName: 'Status', flex: 1
    , renderCell: ({ value, row }) =>
      (row.connection_state === 'export' ? <span style={{ color: 'orange' }}>Exportando</span> : status_dict[value || row.connection_state])
  })
  columns.push({ field: 'usage', headerName: 'Conversas', flex: 1 })
  columns.push({
    field: 'actions', headerName: 'Ações', flex: 1,
    renderCell: ({ row }) => (
      <>
        {row.connection_state !== 'export' &&
          <>
            <Tooltip title={row.status == "open" ? "Desconectar" : "Conectar"}>
              <IconButton className={'action-button'} sx={{ color: '#21446C' }} onClick={() => {
                if (row.status === 'open') {
                  setState({ confirmDisconnect: row })
                } else {
                  connectDisconnect(row.id, row.status, setState)
                }

              }}>
                <MultipleStopIcon className='editicon' />
              </IconButton>
            </Tooltip>
            {row.status !== 'open' && <Tooltip title="Reenviar convite">
              <IconButton className={'action-button'} sx={{ color: '#21446C' }} onClick={() => setState({ showRenotify: row })}>
                <EmailIcon />
              </IconButton>
            </Tooltip>}
            <Tooltip title="Histórico de conexões">
              <IconButton className={'action-button'} sx={{ color: '#21446C' }} onClick={() => setState({ showHistory: row })}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Lista de Exclusão">
              <IconButton className={'action-button'} sx={{ color: '#21446C' }} onClick={() => setState({ showIgnoreList: row })}>
                <VoiceOverOffRoundedIcon />
              </IconButton>
            </Tooltip>
            {(row.status !== 'open') &&
              <Tooltip title="Excluir">
                <IconButton className={'action-button delete'} onClick={() => row.status === 'open' ? useNotification(
                  "Erro!",
                  `É necessário desconectar o número antes de excluí-lo.`,
                  "danger"
                ) : setState({ confirmDelete: row })}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
          </>
        }
      </>
    )
  })
  return columns
}

const getAccounts = async (setState) => {
  setState({ loading: true })

  const params = new URLSearchParams({
      fullFetch: 1,
  });

  const res = await api.get(
    `api/enterprises/${variable_enterprise.id}/scout_accounts/?${params.toString()}`
  );
  if (res.status === 200) {
    setState({ loading: false, accounts: res.data, showFormModal: false })
  }
}

const addScout = async (name, number, email, setState) => {
  setState({ loading: true })
  const res = await api.post(
    `api/enterprises/${variable_enterprise.id}/scout_accounts/`,
    {
      name: name,
      phone_number: number,
      email: email,
    }
  );
  if (res.status === 409) {
    useNotification(
      "Erro!",
      `Este número de telefone já está em uso por outra conta.`,
      "danger"
    );
    setState({ loading: false })
    return
  }

  if (res.status === 400) {
    useNotification(
      "Erro!",
      `Número máximo de licenças atingido.`,
      "danger"
    );
    setState({ loading: false })
    return
  }

  getAccounts(setState);
};

const deleteScout = async (id, setState) => {
  setState({ loading: true })
  const res = await api.delete(
    `api/enterprises/${variable_enterprise.id}/scout_accounts/${id}/`
  );
  if (res.status !== 204) {
    useNotification(
      "Erro!",
      `[${res.status}] ${res.data}`,
      "danger"
    );
  }
  getAccounts(setState);
};

const connectDisconnect = async (id, status, setState) => {
  if (status == "open") {
    setState({ loading: true })
    const res = await api.post(
      `api/enterprises/${variable_enterprise.id}/scout_accounts/${id}/disconnect`
    );

    if (res.status === 200) {
      getAccounts(setState);
    } else {
      useNotification(
        "Erro!",
        `[${res.status}] ${res.data.response.message[0]}`,
        "danger"
      );
    }
    setState({ loading: false })

  } else {
    setState({ showConnectModal: id });
  }
};

const renotify = async (data, setState) => {
  setState({ loading: true })
  const res = await api.get(
    `api/enterprises/${variable_enterprise.id}/scout_accounts/${data.id}/renotify/`
  );
  setState({ loading: false })
  if (res.status === 200) {
    useNotification(
      "Sucesso!",
      `Notificação reenviada com sucesso.`,
      "success"
    );
  } else {
    useNotification(
      "Erro!",
      `Não foi possível reenviar a notificação`,
      "danger"
    );
  }
}

const exportallmessages = async (data, handleClose) => {
  const enterprise = getSession().profile.enterprise;

  const res = await api.post(`api/enterprises/${enterprise.id}/scout_accounts/${data.id}/exportallmessages/`, {
    ...data,
  })
  if (res.status === 200) {
    useNotification(
      'Sucesso!',
      'A exportação de conversas foi iniciada e em breve estará disponível na caixa de e-mail selecionada. O número será excluido automáticamente.',
      'success'
    );

    handleClose()
  }
  else if (res.status === 409) {
    useNotification(
      'Recurso ocupado',
      'Já existe uma exportação em andamento para sua empresa, aguarde até que ela seja concluída, ou tente novamente em 1 hora.',
      'danger'
    );
  }
  else {
    useNotification(
      'Não encontrado',
      'Não foi possível exportar as conversas desse número.',
      'danger'
    );
  }
}

const CustomToolbar = (setState) => {

  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <LoadingButton
        size="small"
        component="label"
        startIcon={<AddCircleIcon />}
        onClick={() => { setState({ showFormModal: true }) }}
      >
        Adicionar
      </LoadingButton>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

const SettingsPage = () => {
  const enterprise = getSession().profile.enterprise;
  variable_enterprise = enterprise
  const [state, setState] = useReducer(defaultReducer, {
    accounts: [],
    showFormModal: false,
    showConnectModal: 0,
    loading: false,
    confirmDelete: null,
    editComponent: null,
    confirmDisconnect: null,
    showHistory: null,
    showRenotify: null,
    showIgnoreList: null,
    exportAll: false,
    email: '',
  })

  useEffect(() => {
    getAccounts(setState);
  }, []);

  const validatedEmail = validateEmail(state.email)

  if (enterprise.platforms.scout) {
    return (
      <Box className="input-settings settings-body">
        <Box className="settings-flexcolumn">
          <Loading loading={state.loading} />
          {state.showRenotify &&
            <ConfirmationDialogCaptcha open={true}
              title="Confirmação"
              description={
                <span>
                  Deseja mesmo reenviar o convite para "{state.showRenotify.name}" no e-mail "{state.showRenotify.email}", digite: <span style={{ 'fontWeight': 'bold' }}>reenviar</span> no campo abaixo.</span>}
              handleClose={() => { setState({ showRenotify: null }); }}
              handleSubmit={() => { setState({ showRenotify: null }); renotify(state.showRenotify, setState); }}
              captcha={'reenviar'}
              alternateNo="Cancelar"
              alternateYes="Confirmar"
              alternateName=""
            />
          }
          {state.confirmDisconnect &&
            <ConfirmationDialogCaptcha open={true}
              title="Confirmação"
              description={
                <span>
                  Deseja mesmo desconectar o número {state.confirmDisconnect.phone_number} ({state.confirmDisconnect.name}), digite: <span style={{ 'fontWeight': 'bold' }}>desconectar</span> no campo abaixo.</span>}
              handleClose={() => { setState({ confirmDisconnect: null }); }}
              handleSubmit={() => { setState({ confirmDisconnect: null }); connectDisconnect(state.confirmDisconnect.id, state.confirmDisconnect.status, setState); }}
              captcha={'desconectar'}
              alternateNo="Cancelar"
              alternateYes="Confirmar"
              alternateName=""
            />
          }
          {state.confirmDelete &&
            <ConfirmationDialogCaptcha open={true}
              title="Confirmação"
              description={
                <>
                  <span>Ao concordar em excluir o número {state.confirmDelete.phone_number} ({state.confirmDelete.name}), <span style={{ fontWeight: 'bold' }}>todas as conversas com esse número serão apagadas do Kwik Scout e não poderão mais ser recuperadas.</span> </span>
                  <br></br>
                  <br></br>
                  <span>

                    <FormControlLabel
                    sx={{margin: 0}}
                      control={
                        <Switch
                          onClick={() => setState({ exportAll: !state.exportAll })}
                          checked={state.exportAll}
                        />
                      }
                      label=""
                    />
                   Marque aqui para baixar um arquivo com todas as conversas antes de remover este usuário.</span>
                  {state.exportAll && <EmailTextField loadEmail={(email) => setState({email: email})}/>}
                  <br></br>
                  <span>Digite: <span style={{ 'fontWeight': 'bold' }}>{state.confirmDelete.name}</span> no campo abaixo para confirmar.</span>
                </>}
              handleClose={() => { setState({ confirmDelete: null }); }}
              handleSubmit={() => { 
                if (state.exportAll){
                  exportallmessages({email:state.email, id:state.confirmDelete.id}, async () => {
                    await getAccounts(setState)
                    setState({ exportAll: null, confirmDelete: null })
                  })
                } else {
                  setState({ confirmDelete: null });
                   deleteScout(state.confirmDelete.id, setState);
                }                
               }}
              disabled={!validatedEmail && state.exportAll}
              captcha={state.confirmDelete.name}
              alternateNo="Cancelar"
              alternateYes="Confirmar"
              alternateName=""
            />
          }
          {state.showHistory && (
            <History
              data={state.showHistory}
              handleClose={() => setState({ showHistory: null })}
            />
          )}
          {state.showIgnoreList && (
            <IgnoreList
              data={state.showIgnoreList}
              handleClose={() => setState({ showIgnoreList: null })}
              setLoading={(e) => setState({ loading: e })}
            />
          )}
          {state.showFormModal && (
            <AddScoutForm
              open={true}
              handleClose={() => setState({ showFormModal: false })}
              submit={(...args) => addScout(...args, setState)}
            />
          )}
          {state.showConnectModal > 0 && (
            <ConnectModal
              accountId={state.showConnectModal}
              enterpriseId={enterprise.id}
              handleClose={() => {
                getAccounts(setState);
                setState({ showConnectModal: 0 })
              }}
            />
          )}
          <Alert className="telegram-info" severity="info">
            <AlertTitle>
              Para saber como conectar um Scout <a href="https://instant.getoutline.com/s/kwik-integracoes/doc/integracao-com-e-mail-v5RCr1SG2H" target="_blank">clique aqui.</a>
            </AlertTitle>
            <Box className="push-s-top">
              Clique no botão Adicionar
            </Box>
          </Alert>
          <Box className="category-title">
            Telefones: {state.accounts.length} de {enterprise.platforms.scout_licenses} licenças utilizadas.
          </Box>
          <Box className="settings-body-bot">
            <StyledEngineProvider injectFirst >
              <ThemeProvider theme={theme}>
                <DataGridPro
                  pageSize={100}
                  pagination
                  disableSelectionOnClick
                  disableDensitySelector
                  density="compact"
                  columns={defColumns(state, setState)}
                  rows={state.accounts}
                  components={{
                    Toolbar: () => CustomToolbar(setState),
                  }}
                />
              </ThemeProvider>
            </StyledEngineProvider>
          </Box>
        </Box>

      </Box >
    );
  } else {
    return (
      <Box>
        <Alert severity="info">
          Entre em contato com seu gerente de contas para contratar o Kwik Scout - O canal de "Compliance"  e gravacao de WhatsApp do Kwik - <a href="https://wa.link/0bs73j">Clique aqui.</a>
        </Alert>
      </Box>
    );
  }
};

export default SettingsPage;
