import React, { useEffect, useReducer } from 'react'

import {
    Box, Dialog, DialogTitle,
    DialogContent, DialogActions, Typography, TextField, Tooltip, Button
} from '@mui/material';

import { validateEmail } from '../../../Report/NewDetailedRegister/report_export_data'
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ptBR from 'dayjs/locale/pt-br';
import { ptBR as localePTBR } from '@mui/x-date-pickers/locales';
import { number2dayweek } from '../../../Report/NewDetailedRegister/detailed_register_leftmenu'
import dayjs from 'dayjs'
import { lockedReducer } from '../../../../utils/defaultReducer';

let timeout = 0

export const EmailTextField = ({ loadEmail }) => {
    const [state, setState] = useReducer(lockedReducer, {
        email: '',
    })

    const validatedEmail = validateEmail(state.email)

    useEffect(()=>{
        clearTimeout(timeout)
        timeout = setTimeout(()=>loadEmail(state.email), 500)
    }, [state.email])

    return (
        <TextField
            size='small'
            label="Email"
            variant="outlined"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
            value={state.email}
            onChange={e => setState({ email: e.target.value })}
            error={!validatedEmail}
            helperText={state.email && !validatedEmail && "Por favor, insira um e-mail válido"}
            inputProps={{
                maxLength: 100
            }}
        />
    )
}

const EmailDialog = ({ activeChat, handleClose, submit, all = false }) => {

    const date = dayjs()

    const [state, setState] = useReducer(lockedReducer, {
        email: '',
        dateini: date.subtract(7, 'day').set('hour', 8).set('minutes', 0),
        dateend: date.set('hour', 18).set('minutes', 0)
    })

    const validatedEmail = validateEmail(state.email)

    return (
        <Dialog
            open={true}
            disablePortal
            disableEnforceFocus
            sx={{ position: "absolute" }}
        >
            <DialogTitle className={'group-flexbox'}>
                <span>Exportar conversa</span>
                <Tooltip title="Fechar" >
                    <Button className="action-button"  >
                        <CloseIcon onClick={handleClose} />
                    </Button>
                </Tooltip>
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider sx={{ justifyContent: 'space-between' }} dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}
                    localeText={localePTBR.components.MuiLocalizationProvider.defaultProps.localeText}

                >
                    <Typography fontSize="12px" fontWeight="500" color="#21446c">
                        {all ? <>Todas as conversas serão enviadas por e-mail e estarão disponíveis por 48h. A conta será excluída após finalização do processo.</>
                            : <>A conversa será enviado por email, para isso, digite-o abaixo.</>}
                    </Typography>
                    <TextField
                        size='small'
                        label="Email"
                        variant="outlined"
                        fullWidth
                        sx={{ mt: 2, mb: 2 }}
                        value={state.email}
                        onChange={e => setState({ email: e.target.value })}
                        error={!validatedEmail}
                        helperText={state.email && !validatedEmail && "Por favor, insira um e-mail válido"}
                        inputProps={{
                            maxLength: 100
                        }}
                    />
                    {!all &&
                        <Box className='scout-align-flex' sx={{ gap: '1rem' }}>
                            <DateTimePicker
                                sx={{ width: '100%', minWidth: '10rem' }}
                                label={'Início'}
                                format={'DD/MM/YYYY HH:mm'}
                                slotProps={{ textField: { size: 'small' } }}
                                value={state.dateini}
                                onChange={e => setState({ dateini: e })}
                                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                ampm={false}
                            />
                            <DateTimePicker
                                sx={{ width: '100%', minWidth: '10rem' }}
                                label={'Fim'}
                                format={'DD/MM/YYYY HH:mm'}
                                slotProps={{ textField: { size: 'small' } }}
                                value={state.dateend}
                                onChange={e => setState({ dateend: e })}
                                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                ampm={false}
                            />
                        </Box>}
                </LocalizationProvider>
            </DialogContent>
            <DialogActions sx={{ padding: '0rem 1.25rem 0.5rem 0rem' }}>
                <Button disabled={state.email.length === 0 || !validatedEmail}
                    onClick={() => { submit(activeChat, state, handleClose) }}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EmailDialog