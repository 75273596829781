import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Box,
  Button,
  Tooltip,
  IconButton,
  ListItem,
  List,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";
import './styles.css'

const ScanQRForm = () => {
  const [showAlert, setShowAlert] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [qrCode, setQRCode] = useState(null);

  const useStyles = makeStyles(() => ({
    title: {
      color: "#21446C",
      fontWeight: 500,
      paddingLeft: "10px",
    },
  }));

  const checkConnection = (enterpriseId, accountId) => {
    const pollingId = setInterval(async () => {
      fetch(
        `/api/api/enterprises/${enterpriseId}/scout_accounts/${accountId}/checkconnection/`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      )
        .then(async (res) => {
          if (res.status == 200) {
            await res.json().then(async (data) => {
              console.log(data);
              if (data.state == "open") {

                clearInterval(pollingId);
                setShowAlert([{
                  severity: 'success',
                  message: "Configuração finalizada com sucesso",
                }])
              } else if (data.state == "wrongNumber") {
                setShowAlert([{
                  severity: 'error',
                  message: "Numero errado, utilize o celular cadastrado na conta para ler o QR.",
                }])
                setQRCode(null);
                setLoading(false);
              } else {
                console.log("Still waiting ...", data.state)
              }
            });
          }
        })
        .catch(async (err) => {
          console.error(err);
        });
    }, 5 * 1000);
  };

  const handleBtnClick = async () => {
    setLoading(true);
    const urlParams = window.location.pathname.split("/");
    const enterpriseId = urlParams[urlParams.length - 4];
    const accountId = urlParams[urlParams.length - 3];
    const uid = urlParams[urlParams.length - 2];
    const token = urlParams[urlParams.length - 1];
    fetch(
      `/api/api/enterprises/${enterpriseId}/scout_accounts/${accountId}/getqr/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          uid: uid,
          token: token,
        }),
      }
    )
      .then(async (res) => {
        if (res.status == 200) {
          await res.json().then(async (data) => {
            setQRCode(data.code);
            checkConnection(enterpriseId, accountId);
          });
        } else {
          setLoading(false);
          setShowAlert([{
            severity: 'error',
            message: "Convite expirado! Entre em contato e solicite um novo.",
          }])
        }
      })
  };

  const classes = useStyles();

  return (
    <Box
      display="flex"
      height="99vh"
      sx={{
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: "100%"
      }}

    >
      <Box sx={{
        display: 'flex',
        width: '50%',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box display="flex">
              <Typography className={classes.title} variant="h4">
                Conecte-se no Kwik Scout
              </Typography>
            </Box>
            <Alert>
                <Typography className={classes.title} variant="h6">
                  Siga o passo-a-passo abaixo para conectar seu WhatsApp ao Kwik Scout
                  As opções vão depender de sua versão de telefone e do aplicativo
                </Typography>
                <List className='scout-informative-list'>
                  <ListItem>No seu telefone, entre no aplicativo WhatsApp</ListItem>
                  <ListItem>No canto inferior direito, selecione 'Configurações'</ListItem>
                  <ListItem>Selecione a opção 'dispositivos conectados'</ListItem>
                  <ListItem>Selecione a opção 'conectar dispositivo'</ListItem>
                  <ListItem>Clique no botão abaixo para gerar o QRCODE necessário</ListItem>
                  <ListItem>Escaneie com o celular o QRCODE gerado</ListItem>
                  <ListItem>Aguarde o término da sincronização entre o WhatsApp e o Kwik Scout</ListItem>
                </List>
              </Alert>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {showAlert.length !== 0 && showAlert.map((alert) => (
              <Alert severity={alert.severity} onClose={() => setShowAlert([])}>
                <Box sx={{ justifyContent: 'center' }}>{alert.message}</Box>
              </Alert>
            ))}
          </Grid>
          {!qrCode && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                type="button"
                onClick={() => handleBtnClick()}
                fontSize="small"
                fullWidth
              >
                {loading && (
                  <CircularProgress
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "10px",
                    }}
                  />
                )}
                Gerar código QR
              </Button>
            </Grid>
          )}
          {qrCode && <QRCode value={qrCode} />}
        </Grid>
      </Box>
    </Box>
  );
};

export default ScanQRForm;
