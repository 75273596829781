import React from "react";

import { Box, Button, Dialog, Tooltip, Chip } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from "@mui/material/Tooltip";
import {
	DataGridPro,
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarFilterButton,
	gridClasses,
	LicenseInfo,
	ptBR
} from '@mui/x-data-grid-pro';

import { ptBR as corePtBR } from '@mui/material/locale';

import {
	createTheme,
	ThemeProvider,
	StyledEngineProvider
} from '@mui/material/styles';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';

import Modal from './Modal';
import Loading from '../../Loading';
import useValidationError from '../../ErrorValidation';
import useNotification from "../../Notification";

import * as moment from "moment";

import api from '../../../api';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import "./styles.css";
import { capitalize } from "../../../utils/capitalize";
import { 
	stringOperators,
	dateOperators,
	booleanOperators,
	choiceOperators
} from "../../../utils/filterOperators";
import ConfirmationDialog from "../../Dialogs/Confirmation";
import ConfirmationDialogCaptcha from '../../Dialogs/ConfimationDialogCaptcha';
import { getSession, saveSession } from "../../../auth";
import userPermissions from "../../User/Permissions";

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_LICENSE_KEY);

const theme = createTheme({
	palette: {
		primary: { main: '#21446C' },
	},
}, ptBR, corePtBR);

const KwikTooltip = styled(({ className, ...props }) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		fontSize: 15,
		padding: 10
	},
}));

class Users extends React.Component {
	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			renderKey: 0,
			dataUsers: [],
			dataColumns: [],
			viewUser: {},
			showUpdateUser: false,
			showLogoutConfirmation: false,
			user_name: '',
			user_id: 0,
			confirmRequeue: false,
			isRequeueLoading: false,
			showCreateUser: false,
			updateUserState: null
		};

		this.groupColors = {
			administrador: '#C95F50',
			supervisor: '#5F8963',
			operador: '#21446c'
		};

		this.settings = {};

		this.user_id = getSession().profile.id
		this.groups = userPermissions()
	}



	CustomToolbar = () => {

		return (
			<GridToolbarContainer className={gridClasses.toolbarContainer}>
				<GridToolbarColumnsButton />
				<GridToolbarFilterButton />
				{this.groups.isAdmin &&
					<LoadingButton
						size="small"
						component="label"
						startIcon={<AddCircleIcon />}
						onClick={() => { this.openCreateModal() }}
					>
						Adicionar
					</LoadingButton>
				}
			</GridToolbarContainer>
		);
	}

	calculateScreenHeight = () => {
		const screenHeight = window.screen.height > 1200 ? 9 :
			window.screen.height > 1100 ? 8 :
				window.screen.height > 1000 ? 7 :
					window.screen.height > 900 ? 6 :
						window.screen.height > 800 ? 5 :
							window.screen.height > 700 ? 4 : 3;

		return screenHeight;
	}


	errorValidation = async (res) => {
		await useValidationError(res.status, res.message);
		await this.setState({ viewUser: {} });
	}

	openUpdateModal = async (data) => {
		await this.setState({
			viewUser       : data,
			showUpdateUser : true,
			renderKey      : Math.random()
		});

	}

	openDeleteModal = async (data) => {
		await this.setState({
			viewUser       : data,
			showDeleteUser : true,
			renderKey      : Math.random()
		});

	}

	openCreateModal = async () => {
		if (this.settings.max_agents === this.state.dataUsers.length) {
			await useNotification(
				'Ops!',
				'Sua empresa atingiu o número máximo de usuários cadastrados.',
				'danger'
			);
			return;
		}

		await this.setState({ showUpdateUser: false });
		this.setState({ showCreateUser: true })
	}

	requeueAgentChats = () => {
		this.setState({ isRequeueLoading: true })
		api.post(`/api/IV/agents/${this.state.user_id}/requeue_chats/`)
			.then((res) => {
				if (res.status == 200) {
					if (this.state.updateUserState)
						this.state.updateUserState();
					else
						this.handleLogoffUser(this.state.user_id);
					this.setState({ confirmRequeue: false, updateUserState: null })
					return;
				}
				this.setState({ isRequeueLoading: false })
				throw res;
			})
			.catch(async (err) => {
				console.error(err);
				useNotification(
					'Ops!',
					'Ocorreu um erro no sistema',
					'danger'
				);
			});
		this.setState({ isRequeueLoading: false })
		this.setState({ confirmRequeue: false })
	}

	setUsersData = (data) => {
		const users = [];

		data.map(user => {
			let obj = user;

			obj.action = user;
			obj.first_name = user.first_name
			obj.last_name = user.last_name

			users.push(obj);
		});

		this.setState({
			dataUsers: users,
			renderKey: Math.random()
		});
	}

	getSettings = () => {

		this.setState({ loading: true });

		api.get(`/api/enterprises/${this.props.enterpriseId}/`)
			.then(async (res) => {
				if (res.status == 200 && this._isMounted) {
					this.settings = res.data;
					return;
				}
				throw res;
			}).catch((err) => {
				console.error(err);
			});

		this.setState({ loading: false });
	}

	getErrorMessage = (message) => {
		message = message.pop();
		if (message == 'Maximum number of extensions reached.')
			return 'O limite máximo de ramais foi atingido.';
		if (message == 'Cant modify an agent logged in.')
			return 'Não é possível modificar um operador logado.';
		if (message == 'Couldnt modify agent lines.')
			return 'Não foi possível modificar as linhas do operador';
		if (message == 'Couldnt modify agent.')
			return 'Não foi possível modificar o operador';
	}

	updateUser =async (idUser, body) => {
		this.setState({ loading: true });

		if ('nickname' in body) {
			if (!('profile' in body))
				body['profile'] = {}
			body['profile']['nickname'] = body['nickname']
		}

		// If we are deactivating a user, we should requeue first
		if (this.state.viewUser && body.is_active === false){
			this.setState({updateUserState: () => this.updateUser(idUser, body), user_id: this.state.viewUser.profile.agent_id})
			if (! await this.handleLogoffUser(this.state.viewUser.profile.agent_id, true)){
				return
			} // if true, should continue, if false, should alert
		}

		api.patch(`/api/enterprises/${this.props.enterpriseId}/users/${idUser}/`, body)
			.then(async (res) => {
				this.setState({ loading: false, viewUser: {} });
				this.setState({ loading: false });
				if (res.status == 200) {
					useNotification(
						'Sucesso!',
						'Usuário alterado com sucesso.',
						'success'
					);
					
					if (getSession().id === res.data.id)
						saveSession(res.data)

					this.getUsers();
					this.setState({ showUpdateUser: false });
					return;
				}

				if (res.status == 400) {
					const message = this.getErrorMessage(res.data);
					useNotification(
						'Atenção!',
						message,
						'warning'
					);
					return;
				}
				throw err;
			})
			.catch(async (err) => {
				console.error(err);
				useNotification(
					'Ops!',
					'Ocorreu um erro no sistema',
					'danger'
				);
			});
	}

	createUser = async (body) => {

		this.setState({ loading: true });

		api.post(`/api/enterprises/${this.props.enterpriseId}/agents/`, body)
			.then(async (res) => {
				await this.setState({ loading: false });
				if (res.status === 400 && res.data && res.data.user &&
					res.data.user[0] === "User must be unique among mcdus, accountcode's queues and extensions") {
					useNotification(
						'Ops!',
						'O número do usuário deve ser único.',
						'danger'
					);
				}
				else if (res.status === 400 && 'email' in res.data && res.data['email'][0] === 'Operador with this email already exists.')
					useNotification(
						'Ops!',
						'Já existe um usuário cadastrado com esse e-mail.',
						'warning'
					);
				else if (res.status !== 201)
					await useValidationError(res.status);
				else {
					await useNotification(
						'Sucesso!',
						'Usuário cadastrado com sucesso.',
						'success'
					);
					this.getUsers();
					this.setState({ showCreateUser: false });
				}
			})
			.catch((err) => {
				console.error(err);
			});

	}

	getUsers = () => {
		this.setState({ loading: true });

		api.get(`/api/enterprises/${this.props.enterpriseId}/users/`)
			.then(async (res) => {
				this.setState({ loading: false });
				if (res.status === 200 && this._isMounted)
					this.setUsersData(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}

	generateDialog = () => {
		return (
			<Modal
				showUpdateUser={this.state.showUpdateUser}
				user={this.state.viewUser}
				groupColors={this.groupColors}
				enterpriseId={this.props.enterpriseId}
				createUser={(body) => this.createUser(body)}
				updateUser={(userId, body) => this.updateUser(userId, body)}
				handleClose={() => this.setState({ showUpdateUser: false })}
				key={this.state.renderKey}
			/>
		);
	}

	generateCreateDialog = () => {
		return (
			<Modal
				showCreateUser={this.state.showCreateUser}
				user={this.state.viewUser}
				groupColors={this.groupColors}
				enterpriseId={this.props.enterpriseId}
				createUser={(body) => this.createUser(body)}
				handleClose={() => this.setState({ showCreateUser: false })}
				key={this.state.renderKey}
			/>
		);
	}

	deleteUser = async (user) => {
		if (getSession().id === user.id) {
			useNotification(
				'Ops!', 
				'Não é possível excluir o seu próprio usuário.', 
				'warning'
			)
			return
		}
		
		this.setState({ loading: true });
		const resLogout = await api.post(`/api/IV/agents/${user.profile.agent_id}/logout/`)
		this.setState({ loading: false });

		if (resLogout.status === 400) {
			if (res.data === 'Você não pode deslogar com atendimentos em andamento')
				useNotification(
					'Ops!', 
					'Não foi possível excluir o usuário, ele possui atendimentos em curso.', 
					'danger'
				)
			else
				useNotification(
					'Ops!', 
					'Algo deu errado, tente novamente, se o problema persistir, entre em contato com o nosso time de suporte.', 
					'danger'
				)
			return
		}

		this.setState({ loading: true });
		const resDelete = await api.delete(`api/users/${user.id}/`)
		this.setState({ loading: false });
		
		if (resDelete.status === 204) {
			this.getUsers()
			this.setState({
				viewUser       : {},
				showDeleteUser : false,
				renderKey      : Math.random()
			})
			useNotification(
				'Sucesso!', 
				'O usuário foi excluído com sucesso.', 
				'success'
			)
		} else {
			useNotification(
				'Ops!', 
				'Algo deu errado, tente novamente, se o problema persistir, entre em contato com o nosso time de suporte.', 
				'danger'
			)
		}
	}

	handleLogoffUser = async (userid, bypass=false) => {
		this.setState({ loading: true });
		let retvalue = true
		const res = await api.post(`/api/IV/agents/${userid}/logout/`)

		if (res.status === 400) {
			if (res.data === 'Você não pode deslogar com atendimentos em andamento')
				this.setState({ confirmRequeue: true })
			else
				useNotification('Atenção!', res.data, 'warning');
			retvalue = false
		}

		if (!bypass)
			this.getUsers();
		
		this.setState({ loading: false });

		return retvalue
	}

	includesAdminOrSupervisor = (groups) => {
		const group_names = groups.map(each => each.name)
		if (group_names.includes('supervisor') || group_names.includes('administrador'))
			return true
		return false
	}

	componentWillMount() {
		this._isMounted = true;
		const user = getSession();
		const has_voice = user.profile.enterprise.has_voice;
		const has_assistant = user.profile.enterprise.has_assistant;

		const dataColumns = [
			{ field: 'username', headerName: 'Usuário', flex: 1, filterOperators: stringOperators },
			{
				field: 'name', headerName: 'Nome', flex: 0.5, filterOperators: stringOperators,
				valueGetter: (params) => {
					return `${params.row.first_name} ${params.row.last_name}`
				}
			},
			{ 
				field: 'nickname', headerName: 'Apelido', flex: 0.5, filterOperators: stringOperators,
				valueGetter: (params) => {
					return `${params.row.profile.nickname}`
				}
			},
			{
				field: 'last_login_local', type: 'dateTime', headerName: 'Último login', flex: 1, filterOperators: dateOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? moment(params.value).format("DD/MM/YYYY HH:mm:ss").toString() : ""}
					</Box>
				),
			},
			{
				field: 'groups', headerName: 'Grupos', flex: 1.5, filterable: false, 
				renderCell: (params) => (
					<Box>
						{params.value.map(group => (
							<Chip
								style={{ marginRight: "0.25rem" }}
								label={capitalize(group.name)}
								color={group.name == "supervisor" ? "success" : group.name == "operador" ? "info" : "error"}
								size="small"
							/>
						))}
					</Box>
				),
			},
			{
				field: 'is_active', headerName: 'Ativo', flex: 0.25, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.value ? <CheckCircleIcon className="active-user" /> : <Cancel className="inactive-user" />}
					</Box>
				),
			},
			{
				field: 'agent', headerName: 'Online', flex: 0.25, filterOperators: booleanOperators,
				renderCell: (params) => {
					return (
						<Box>
							{params.row.profile.agent.agentstatus == 'LOGIN'
								? <CheckCircleIcon className="active-user" />
								: <Cancel className="inactive-user" />
							}
						</Box>
					)
				},
			},
		];

		if (has_voice) {
			let column = {
				field: 'profile', headerName: 'Voz', flex: 0.25, filterOperators: booleanOperators,
				renderCell: (params) => (
					<KwikTooltip
						title={params.value.agent.preferred_interface
							? params.value.agent.external_username
							? 'Ramal(Externo): ' + params.value.agent.external_username
							: 'Ramal(Interno): ' + params.value.agent.preferred_interface.split('_').pop()
							: 'Ramal não configurado'}
						placement="left"
						disableInteractive
						arrow
					>
						<Box>
							{params.value.agent.preferred_interface
								? <CheckCircleIcon className="active-user" />
								: <Cancel className="inactive-user" />
							}
						</Box>
					</KwikTooltip>
				),
			};
			dataColumns.push(column);
		}

		if (has_assistant) {
			let column = {
				field: 'has_assistant', headerName: 'Cortex', flex: 0.25, filterOperators: booleanOperators,
				renderCell: (params) => (
					<Box>
						{params.row.profile.has_assistant
							? <CheckCircleIcon className="active-user" />
							: <Cancel className="inactive-user" />
						}
					</Box>
				),
			};
			dataColumns.push(column);
		}

		dataColumns.push({
			field: 'action', headerName: 'Ações',
			sortable: false, filterable: false,
			renderCell: (params) => (
				<>
					{this.groups.isAdmin &&
						<>
							<Tooltip title="Editar" >
			                    <Button
			                        className="action-button"
			                        onClick={() => this.openUpdateModal(params.value)}
			                    >
			                        <EditIcon />
			                    </Button>
			                </Tooltip>
			                <Tooltip title="Excluir">
			                    <Button className="action-button delete"
			                        onClick={() => this.openDeleteModal(params.value)}>
			                        <DeleteIcon />
			                    </Button>
			                </Tooltip>
						</>
					}
					{params.row.profile.agent.agentstatus == 'LOGIN' && params.row.profile.id != this.user_id ?
						<>
							{(
								(this.groups.isSupervisor && !this.groups.isAdmin && !this.includesAdminOrSupervisor(params.row.groups))
								|| this.groups.isAdmin) &&
								<>
									<Tooltip title="Deslogar" >
										<Button className="action-button inactive-send"
											onClick={() => {
												this.setState({
													user_id: params.row.profile.agent_id,
													user_name: params.row.first_name,
													user_surname: params.row.last_name,
													showLogoutConfirmation: true
												})
											}}>
											<LogoutIcon />
										</Button>
									</Tooltip>
								</>
							}

						</>
						:
						null
					}
				</>
			),
		});

		this.state.dataColumns = dataColumns;

		this.getSettings();
		this.getUsers();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		return (
			<>
				<Loading loading={this.state.loading} />
				{this.state.showUpdateUser &&
					<Dialog open={true} fullWidth
						disablePortal
						disableEnforceFocus
						sx={{ position: 'absolute' }}
					>
						{this.generateDialog()}
					</Dialog>
				}
				{this.state.showCreateUser &&
					<Dialog open={true} fullWidth
						disablePortal
						disableEnforceFocus
						sx={{ position: 'absolute' }}
					>
						{this.generateCreateDialog()}
					</Dialog>
				}
				{this.state.showDeleteUser &&
					<ConfirmationDialogCaptcha open={this.state.showDeleteUser}
		                title="Confirmação"
		                description={
		                    <span>
		                        Ao concordar, o usuário 
		                        <span style={{ 'fontWeight': 'bold' }}> {this.state.viewUser.first_name + ' ' + this.state.viewUser.last_name} </span> 
		                        (e sua respectiva fila) serão excluídos do sistema, para confirmar a ação, digite: 
			                    <span style={{ 'fontWeight': 'bold' }}> remover </span> 
			                    no campo abaixo.
		                    </span>
		               	}
		                handleClose={() => this.setState({
							viewUser       : {},
							showDeleteUser : false,
							renderKey      : Math.random()
						})}
		                handleSubmit={() => this.deleteUser(this.state.viewUser)}
		                captcha="remover"
		            />
				}
				<ConfirmationDialog open={this.state.confirmRequeue}
					title="Não é permitido deslogar com chats em atendimento"
					description={(
						<Box>
							Deseja que os chats em atendimento sejam
							transferidos de volta para suas respectivas
							filas de origem?
						</Box>
					)}
					handleClose={() => this.setState({ confirmRequeue: false })}
					handleSubmit={() => this.requeueAgentChats()}
					submitButtonLoading={this.state.isRequeueLoading}
				/>
				<ConfirmationDialog open={this.state.showLogoutConfirmation}
					title="Logout"
					description={(
						<Box>
							Deseja realizar logout do usuário {this.state.user_name}?
						</Box>
					)}
					handleClose={() => this.setState({ showLogoutConfirmation: false })}
					handleSubmit={() => {
						this.handleLogoffUser(this.state.user_id)
						this.setState({ showLogoutConfirmation: false })
					}}
					submitButtonLoading={false}
				/>
				<Box className='settings-body-bot' key={this.state.renderKey}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<DataGridPro
								pageSize={100}
								pagination
								disableSelectionOnClick
								disableDensitySelector
								density="compact"
								columns={this.state.dataColumns}
								rows={this.state.dataUsers}
								components={{
									Toolbar: this.CustomToolbar,
								}}
							/>
						</ThemeProvider>
					</StyledEngineProvider>
				</Box>
			</>
		);
	}
}

export default Users;