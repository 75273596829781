import React, { useRef } from 'react'
import { List, ListItemButton, ListItemText, ListItem, Box, Tooltip } from '@mui/material'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ChatList from '../ChatList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const status_translated = {
    'open': 'Conectada',
    'connecting': 'Conectando',
    'close': 'Desconectada'
}

const AccountListButton = ({ activeAccount, account, activate, activeChat, chats = null, setActiveChat = () => { }, setGrandParent = () => { }}) => {

    const accountListRef = useRef()

    return (

        <>
            <Tooltip title={`Conta ${status_translated[account.status || account.connection_state]}`}>
                <span>
                    <ListItemButton
                        onClick={activate}
                        className={`scout-list-item-account ${activeAccount?.id === account.id ? 'active' : account.status || account.connection_state}`}
                        disableGutters
                        key={`account-item-main-id-${account.id}`}
                    >

                        <ListItem>
                            <ListItemText primary={
                                <Box className={`scout-title-accountheader ${activeAccount?.id === account.id ? 'active' : ''}`}>
                                    <span className='scout-title-subheader'>
                                        <span className='icon'>{<SupervisorAccountIcon />}</span>
                                        <span className='scout-wrapper-text'>
                                            <span className='scout-limit-text'>{account.name}</span>
                                            <span className='scout-limit-text'> {account.phone_number}</span>
                                        </span>
                                    </span>
                                    <span className='icon'>{activeAccount?.id === account.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                </Box>
                            } />
                        </ListItem>
                    </ListItemButton>
                </span>
            </Tooltip>
            {chats && <Box className='scout-chatlist'>
                <ChatList
                    listRef={accountListRef}
                    activeChat={activeChat}
                    chats={chats}
                    account={activeAccount}
                    setGrandParent={setGrandParent}
                    setActiveChat={setActiveChat} />
            </Box>}
        </>
    )
}

const AccountList = ({ parent, setParent }) => {

    return (
        <>
            <List disablePadding className='scout-list'>
                {parent.activeAccount &&
                    <AccountListButton
                        activeAccount={parent.activeAccount}
                        account={parent.activeAccount}
                        activate={() => setParent({ activeAccount: null, activeChat: null })}
                        activeChat={parent.activeChat}
                        chats={parent.chats}
                        setGrandParent={setParent}
                        setActiveChat={(chat) => setParent({ activeChat: chat })}
                    />}
                {parent.accounts.filter(account => account.id !== parent.activeAccount?.id).toSorted((x, y) => {
                    if (x.status === 'open' && y.status === 'open') return x.priority-y.priority
                    else if (x.status === 'open') return -1
                    else if (y.status === 'open') return 1
                    else return 0
                }).map((account) => (
                    <AccountListButton
                        activeAccount={parent.activeAccount}
                        account={account}
                        activate={() => {
                            if (account.status !== 'open') return
                            parent.accounts.forEach(acc => acc.priority = (acc === account ? 1 : (account.priority || 1) + 1)); setParent({ activeAccount: account, activeChat: null })
                        }}
                    />
                ))
                }
            </List >
        </>
    )
}

export default AccountList