import React, { useEffect, useRef, useState, useReducer } from 'react'
import {
    Avatar, Box, List, ListItem, ListItemAvatar, ListItemButton, ListItemText,
    Tooltip, Typography, InputBase, Paper, InputAdornment
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import dayjs from 'dayjs'
import { lockedReducer } from '../../../../utils/defaultReducer';
import { ShowComponent } from '../../../../utils/isVisible';
import { getSession } from '../../../../auth';
import api from '../../../../api';

const defaultLimit = 10000
let lockTimeout = 0
const defaultLockLimit = 100

export const groupIcons = {
    'CONTACT': { name: 'Contato', icon: (<PersonIcon />) },
    'GROUP': { name: 'Grupo', icon: (<GroupsIcon />) }
}

const parseSender = (fromMe, sender) => {
    if (fromMe) return 'Você'
    return sender
}

const parseLastMessage = (message, messageType) => {

    if (messageType === 'conversation')
        return message.conversation || message.message
    else if (messageType === 'senderKeyDistributionMessage')
        return message.conversation
    else if (messageType === 'reactionMessage')
        return 'Reagiu a uma mensagem'
    else if (messageType === 'templateMessage')
        return message?.templateMessage?.hydratedTemplate?.hydratedContentText || ''
    else if (messageType === 'imageMessage' )
        return 'Enviou uma imagem'
    else if (messageType === 'audioMessage')
        return 'Enviou uma áudio'
    else if (messageType === 'stickerMessage')
        return 'Enviou uma figurinha'
    else if (messageType === 'extendedTextMessage')
        return message?.extendedTextMessage?.canonicalUrl || message?.extendedTextMessage?.text ||  message.message || ''
    else if (messageType === 'documentWithCaptionMessage')
        return 'Enviou um documento'
    else if (messageType === 'protocolMessage')
        return message?.message || message?.protocolMessage?.editedMessage?.conversation || 'Essa mensagem foi apagada.'
    else if (messageType === 'messageContextInfo')
        return (message?.editedMessage?.message?.protocolMessage?.editedMessage?.conversation)
        
    else
        return ''
}

export const transformTimeData = (time) => {
    const date = dayjs(time * 1000)
    const today = dayjs()
    if (date.day() === today.day())
        return date.format('HH:mm')
    else if (date.day() === today.subtract(1, 'day').day())
        return 'Ontem'
    else
        return date.format('DD/MM/YYYY')
}

const getConversations = async (enterprise_id, account_id, account_num) => {
    const params = new URLSearchParams({
      limit: defaultLimit, 
      skip: 0,
      sort: 'desc'
  });
  const signal = window.signal
  const res = await api.get(
    `api/enterprises/${enterprise_id}/scout_accounts/${account_id}/conversations/?${params.toString()}`
  , {signal, timeout: 30000});
  if (res.status === 200) {
    return res.data.filter(chat => chat.id.split('@')[0] !== account_num ).map(chat => ({ account_id: account_id, ...chat }))
  }
  return []
}

const loadChats = async (enterprise, account, setState, setGrandParent) => {
    clearTimeout(lockTimeout)
  setState({lock:true, cursor: 0})
  setGrandParent({ loading: true, chats:[] })
  const conversations = await getConversations(enterprise.id, account.id, account.phone_number)
  setGrandParent({ loading: false, chats: conversations})
  setState({cursor: defaultLimit})
  lockTimeout = setTimeout(() => { setState({ lock: false }) }, defaultLockLimit)
}

const getMoreConversations = async (enterprise_id, account_id, cursor, chats, setState, setGrandParent) => {
    if ([-1, 0].includes(cursor)) return
    clearTimeout(lockTimeout)
    setState({lock:true})
    const params = new URLSearchParams({
      limit: defaultLimit, 
      skip: cursor,
      sort: 'desc'
  });
  const signal = window.signal
  const res = await api.get(
    `api/enterprises/${enterprise_id}/scout_accounts/${account_id}/conversations/?${params.toString()}`
  , { signal, timeout: 30000 });
  if (res.status === 200 && !signal.aborted) {
    setState({cursor: res.data.length > 0 ? defaultLimit + cursor : -1 })
    const newChats = Object.fromEntries([...chats, ...res.data.map(chat => ({ account_id: account_id, ...chat }))].map(chat => [chat.id, chat]))
    setGrandParent({chats: Object.values(newChats)})
  }

  lockTimeout = setTimeout(() => { setState({ lock: false }) }, defaultLockLimit)
}

let timeout = 0

export const FilterComponent = ({ updateFilter, alternative }) => {

    const [text, setText] = useState('')

    useEffect(() => {
        clearTimeout(timeout)
        timeout = setTimeout(() => { updateFilter(text) }, 500)
    }, [text])

    const title = alternative ? 'Pesquisar contatos' : 'Pesquisar conversas'

    return (
        <Box className={'filter-chats finished-chats-filter bluepadding'} >
            <Paper
                component="form"
                sx={{ display: 'flex', alignItems: 'center', width: 500, height: 50, bgcolor: '#efefef' }}
            >
                <Tooltip title="Filtrar por número de telefone ou nome do contato." placement="bottom" disableInteractive >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={title}
                        onChange={(event) => {
                            setText(event.target.value)
                        }}
                        value={text}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                </Tooltip >
            </Paper>
        </Box>
    )
}

const ChatList = ({ listRef, chats, account, activeChat, setActiveChat, setGrandParent }) => {

    const enterprise = getSession().profile.enterprise
    const [filter, setFilter] = useState('')
    const [state, setState] = useReducer(lockedReducer, {
        cursor: 0,
        lock: false
    })

    useEffect(()=>{
        const controller = new AbortController()
        window.signal = controller.signal
        if (account) loadChats(enterprise, account, setState, setGrandParent)
        return () => {
            controller.abort()
            clearTimeout(lockTimeout)
            setState({ cursor: 0, lock: true })
        }
    }, [account])


    return (
        <>
            <FilterComponent updateFilter={setFilter} />
            <List disablePadding className='scout-list'>
                {chats.
                    filter((chat) => chat.name?.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) || chat.phone_num?.includes(filter)).
                    toSorted((x, y) => y.last_message_timestamp - x.last_message_timestamp).
                    map(chat => (
                        <Tooltip title={groupIcons[chat.type]?.name}>
                            <ListItemButton
                                onClick={() => setActiveChat(chat)}
                                className={`scout-list-item-chat ${activeChat?.id === chat.id ? 'active' : ''}`}
                                disableGutters
                                key={`chat-item-main-id-${chat.id}`}
                            >
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar alt={chat.id} src={chat.profile_picture} />
                                    </ListItemAvatar>
                                    <ListItemText

                                        primary={
                                            <Box className={`scout-title-header ${activeChat?.id === chat.id ? 'active' : ''}`}>
                                                <span className='icon'>{groupIcons[chat.type]?.icon}</span>
                                                <span className='scout-preview-message'>{chat.name || chat.phone_num || '' }</span>
                                            </Box>
                                        } secondary={
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color={`${activeChat?.id === chat.id ? '#dadada' : '#6d6d6dde'}`}
                                            >
                                                <Box className='scout-info-bearer'>
                                                    <span className='scout-preview-message'>{parseSender(chat.fromMe, chat.sender)}: {parseLastMessage(chat.message, chat.message_type)}</span>
                                                    <Box className='scout-time-bearer'>{transformTimeData(chat.last_message_timestamp)}</Box>
                                                </Box>
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            </ListItemButton>
                        </Tooltip>
                    ))
                }
                {(state.cursor !== -1 && !state.lock) &&
                    <ShowComponent
                        key={`show-chat-component-${state.cursor}`}
                        parent={listRef}
                        loading={(state.cursor !== -1)}
                        isVisibleHook={(e) => {
                            if (e) getMoreConversations(enterprise.id, account.id, state.cursor, chats, setState, setGrandParent)
                        }}>
                    </ShowComponent>}
            </List >
        </>
    )
}

export default ChatList