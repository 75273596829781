import React from 'react'

import { Dialog, DialogContent, DialogTitle, Button, Box, Tooltip, List, ListItem } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { transformTimeData } from '../ChatList'
import { parseMessage } from '../ChatWindow'

import dayjs from 'dayjs'


const EditList = ({ data = [], handleClose }) => {

    return (
        <Dialog
            open={true}
            fullWidth
            disablePortal
            disableEnforceFocus
            maxWidth={'md'}
            sx={{ position: "absolute" }}
        >
            <DialogTitle className="group-flexbox">
                Edições
                <Button sx={{ minWidth: "2rem", padding: "0" }} onClick={handleClose}>
                    <CloseIcon />
                </Button>
            </DialogTitle>
            <DialogContent>
                <List
                    className='scout-window-content-list'
                    key={`unique-key-by-activechat-on-edit`}>
                    {data.map((message, index) => (
                        <ListItem disableGutters
                            key={`individual-message-edit-id-${index}`}
                            className={`scout-window-content-item ${message.fromMe ? 'right' : 'left'}`}>
                            <Box className='scout-conversation-box'>
                                {parseMessage(message, [])}
                                <Tooltip title={dayjs(message.timestamp * 1000).format('DD/MM/YYYY HH:mm')}>
                                    <Box className='scout-conversation-time'>{transformTimeData(message.timestamp)}
                                    </Box>
                                </Tooltip>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default EditList