import React, { useState, useCallback, useEffect } from "react";
import api from "../../../../api";

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Divider,
  Box,
} from "@mui/material";
import QRCode from "react-qr-code";

import LoadingButton from "@mui/lab/LoadingButton";
import Loading from "../../../Loading/index.js";
import useNotification from "../../../Notification";

import CloseIcon from "@mui/icons-material/Close";

let intervalId = 0
let pollingId = 0

export default function ConnectModal({ accountId, enterpriseId, handleClose }) {
  // const [name, setName] = useState();
  // const [number, setNumber] = useState();
  const [loading, setLoading] = useState(true);
  const [qrCode, setQRCode] = useState(null);

  const getQR = useCallback(async () => {
    setLoading(true);
    const res = await api.get(
      `api/enterprises/${enterpriseId}/scout_accounts/${accountId}/getqr/`,
      { timeout: 30000 }
    );
    if (res.status === 200) {
      setQRCode(res.data.code);
    } else {
      console.error("Error generating qr", res);
      useNotification(
        "Erro!",
        `Erro ao gerar código QR.`,
        "danger"
      );
    }
    setLoading(false);
  });

  const checkConnection = useCallback(async () => {
    const res = await api.get(
      `api/enterprises/${enterpriseId}/scout_accounts/${accountId}/checkconnection/`
    );
    if (res.status === 200) {
      if (res.data.state == "open") {
        handleClose();
      } else if (res.data.state == "wrongNumber") {
        useNotification(
          "Erro!",
          "Número incorreto",
          "danger");
        handleClose();
      }
    } else {
      console.error("Error checking connection", res);
      handleClose();
    }
  });

  useEffect(() => {
    getQR();
    clearInterval(intervalId);
    clearInterval(pollingId);
    intervalId = setInterval(async () => {
      await getQR();
    }, 30 * 1000);

    pollingId = setInterval(async () => {
      await checkConnection();
    }, 5 * 1000);

    return () => {
      // Cleanup on component unmount
      clearInterval(intervalId);
      clearInterval(pollingId);
    };
  }, [accountId]);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        disablePortal
        disableEnforceFocus
        maxWidth='xs'
        sx={{ position: "absolute" }}
      >
        <Loading loading={loading} />
        <DialogTitle className="group-flexbox">
          Conectar com o Scout
          <Button sx={{ minWidth: "2rem", padding: "0" }} onClick={handleClose}>
            <CloseIcon />
          </Button>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {qrCode && <QRCode value={qrCode} />}

        </DialogContent>
        <DialogActions>
          <Box className="user-footer">
            <LoadingButton className="button-created" onClick={handleClose}>
              Cancelar
            </LoadingButton>
            <LoadingButton
              className="button-created"
              onClick={() => submit(name, number)}
              disabled={!name || !number}
            >
              Salvar
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
