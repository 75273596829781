import React, { useState, useEffect, useRef } from 'react';

import { Box, Popover, IconButton, Button, InputLabel } from '@mui/material';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ContentEditable from 'react-contenteditable'

import Picker from 'emoji-picker-react';
import { reverseEmojiDict, emojiReplace, reverseEmojiReplace, emojiURLDict, AppleDataSource, exceptionClassEmoji } from '../../../utils/emojiDict'

import './styles.css';

const stripHtml = (event) => {
	event.preventDefault();
	var text = event.clipboardData.getData("text/plain");
	text = text.replace(/<[^>]*>/g, "");
	document.execCommand("insertHTML", false, text);
}

const stopDrop = (event) => {
	event.preventDefault();
	var text = event.dataTransfer.getData("text/plain");
	text = text.replace(/<[^>]*>/g, "");
	document.execCommand("insertHTML", false, text);
}

const KwikAutoSizer = (props) => {
	const myRef = useRef('')
	const [refresh, useRefresh] = useState(false)
	const reloadState = () => useRefresh(p => !p)

	const handleKeyDown = evt => {
		//myRef.current = '' // Was cleaning message if alt+tab were pressed together.
		props.onKeyDown(evt)
	}

	const handleChange = evt => {

		evt.target.value = evt.target.value.replaceAll('&nbsp;', ' ').replaceAll('\n', '<br>')

		const replacedValues = emojiReplace(evt.target.value)

		myRef.current = replacedValues

		const recoveredValues = reverseEmojiReplace(replacedValues).replaceAll('<br>', '\n')

		evt.target.value = recoveredValues

		props.onChange(evt)

		reloadState()

	};

	useEffect(() => {
			const event = { target: { value: props.value.substring(0,props.maxLength) } }
			handleChange(event)
	}, [props.value])

	const shrink = myRef.current !== ''

	return (
		<span className='emojitextarea-wrapper-box'>

			<ContentEditable
				{...props}
				html={myRef.current}
				onPaste={props.handlePaste || stripHtml}
				onDrop={stopDrop}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
			/>
			{props.inside}
			{props.componentName === 'MessageBox' ?
				<>
					{<InputLabel shrink={shrink} className={`emojitextarea-placeholder offset ${shrink?'shrink':''}`}>{props.placeholder}</InputLabel>}
				</>
				:
				<>
					{myRef.current == '' && <Box className='emojitextarea-placeholder'>{props.placeholder}</Box>}
				</>
			}
			{props.dropdown}
		</span>
	)
}

class EmojiTextarea extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			showPopover: false,
			anchorEl: null,
		};
	}

	onEmojiClick = (event) => {
		let text = this.props.text;
		const foundText = Object.entries(reverseEmojiDict).find(([key, value]) => key.split('_').pop() === event.unified)
		text += foundText ? foundText.pop() : event.unified in exceptionClassEmoji ? exceptionClassEmoji[event.unified] : event.emoji
		this.props.setEmoji(text);
		this.setState({
			showPopover: false,
			anchorEl: null
		});
	}

	getEmojiURL = (obj) => {
		if (obj in exceptionClassEmoji)
			return `${AppleDataSource}${obj}.png`
		return emojiURLDict[obj]
	}


	render() {

		return (
			<>
				{!this.props.toView &&
					<Popover
						sx={{zIndex:2001}}
						className="popover-predefinedMessage"
						open={this.state.showPopover}
						anchorEl={this.state.anchorEl}
						onClose={() => this.setState({
							showPopover: false,
							anchorEl: null
						})}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<Picker
							onEmojiClick={this.onEmojiClick}
							disableAutoFocus={true}
							lazyLoadEmojis={true}
							previewConfig={{ showPreview: false }}
							searchPlaceHolder='Procurar'
							suggestedEmojisMode='recent'
							getEmojiUrl={this.getEmojiURL}
							categories={[
								{
									category: 'suggested',
									name: 'Sugerido'
								},
								{
									category: 'smileys_people',
									name: 'Smileys e pessoas'
								},
								{
									category: 'animals_nature',
									name: 'Animais e natureza'
								},
								{
									category: 'food_drink',
									name: 'Comidas e bebidas'
								},
								{
									category: 'travel_places',
									name: 'Viagens e lugares'
								},
								{
									category: 'activities',
									name: 'Atividades'
								},
								{
									category: 'objects',
									name: 'Objetos'
								},
								{
									category: 'symbols',
									name: 'Símbolos'
								},
								{
									category: 'flags',
									name: 'Bandeiras'
								}
							]}
						/>
					</Popover>
				}
				{!this.props.toView && this.props.childComponent === 'PredefinedMessages' &&
					<IconButton
						onClick={(event) => this.setState({
							showPopover: true,
							anchorEl: event.currentTarget
						})}
					>
						<InsertEmoticonIcon
							className="emoticon-icon"
						/>
					</IconButton>
				}
				{this.props.childComponent === 'Chat' &&

					<KwikAutoSizer
						className={
							"textarea-autosize textarea-chat"
						}
						value={this.props.text}
						onChange={this.props.setText}
						onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : () => { }}
						placeholder={this.props.placeholder}
						disabled={this.props.toView}
						emojiSelected={this.state.showPopover}
						maxLength={this.props.maxLength ? this.props.maxLength : 5000}
						handlePaste={this.props.handlePaste}
					/>
				}
				{(!this.props.toView &&
					this.props.childComponent === 'Chat')
					&&
					<IconButton
						sx={{ padding: 0 }}
						onClick={(event) => this.setState({
							showPopover: true,
							anchorEl: event.currentTarget
						})}
					>
						<InsertEmoticonIcon

							className="emoticon-icon"
						/>
					</IconButton>
				}

				{this.props.childComponent === 'MessageBox' &&

					<Box sx={{flex:1}}>
						<KwikAutoSizer
							className={
								`textarea-autosize textarea-messagebox ${this.props.dropdown ? 'padding-for-dropdown' : ''}`
							}
							value={this.props.text}
							onChange={this.props.setText}
							onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : () => { }}
							placeholder={this.props.label}
							disabled={this.props.toView}
							emojiSelected={this.state.showPopover}
							componentName={this.props.childComponent}
							maxLength={this.props.maxLength ? this.props.maxLength : 1000}
							handlePaste={this.props.handlePaste}
							inside={
								<IconButton
									sx={{ padding: 0 }}
									className='textarea-messagebox-button'
									onClick={(event) => this.setState({
										showPopover: true,
										anchorEl: event.currentTarget
									})}
								>
									<InsertEmoticonIcon

										className="emoticon-icon"
									/>
								</IconButton>
							}
							dropdown={this.props.dropdown}

						/>


					</Box>
				}

			</>
		);
	}
}

export default EmojiTextarea;