// This file contains all information from the original Contact report.
import React, { useReducer } from 'react'

import {
    Box,
    Button,
    Tooltip,
    ListItemButton,
    List,
    ListItem
} from "@mui/material";

import {
    useGridApiContext,
    useGridSelector,
    GridRow,
    gridFilteredDescendantCountLookupSelector
} from '@mui/x-data-grid-pro'

import {
    formatPhoneNumber, fetchHistoryMessages, numericOperators, booleanOperators,
    choiceOperators, dateOperators, filterOperators, fetchTags
} from './detailed_register_functions'

import api from '../../../api';

import ExportIcon from '@mui/icons-material/GetApp';
import ChatIcon from '@mui/icons-material/Chat';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstantChatIcon from '@mui/icons-material/Language';
import PlayIcon from '@mui/icons-material/PlayCircle';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PauseIcon from '@mui/icons-material/Pause';
import MicrosoftIcon from '@mui/icons-material/Groups';
import HistoryMessagesDialog from "../../Dialogs/HistoryMessagesDialog";
import useNotification from '../../Notification';
import ApiIcon from '@mui/icons-material/DataObject';

import { getSession } from "../../../auth";
import { lockedReducer } from '../../../utils/defaultReducer';
import TagsDialog from './tags_dialog';
import LabelIcon from '@mui/icons-material/Label';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import EmailIcon from '@mui/icons-material/Email';

const user = getSession()
const enterpriseId = user?.profile.enterprise.id


export const CustomRow = (props) => {
    const apiContext = useGridApiContext();
    const rowNode = apiContext.current.getRowNode(props.rowId);

    return (
        <GridRow
            {...props}
            className={(rowNode?.depth ?? 0) > 0 ? 'custom-row' : ''}
        />
    );
}

export const groupingColDef = {
    field: 'transfer',
    headerName: 'Transferência',
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />
}

export const handleReportRows = (data) => {
    let uniqueidAndHour = {};
    let rows = [];
    let hasTransfers = false;
    let lastSurvey = 1
    let hasComment = false
    const survey_show = {}

    data.map((item, index) => {
        handleReportRow(item, uniqueidAndHour)
        rows.push(item)
        item.transfers.forEach((transfer, index) => {
            handleReportRow(transfer, uniqueidAndHour);
            transfer.actions = 'transfer_' + transfer.uniqueid + '_' + index;
            let transferPath = transfer.uniqueid + '_' + transfer.time;
            transfer.path = item.path.concat(transferPath);
            transfer.platform_entrypoint = item.platform_entrypoint;
            rows.push(transfer);
            hasTransfers = true;
        });
        if (lastSurvey < 10 && `survey_number_${lastSurvey + 1}` in item) {
            survey_show[`survey_number_${lastSurvey}`] = true
            lastSurvey += 1
        }
        if (!hasComment && 'survey_comment' in item && item.survey_comment !== '') {

            hasComment = true
            survey_show['survey_comment'] = true
        }
    })

    return rows

}

export const buildContactColumns = (data, setParent) => {

    const platformOptions = ['Whatsapp', 'ChatWeb', 'Telegram', 'Instagram', 'Messenger', 'Email', 'Voz', 'API'];
    if (user.profile.enterprise.integration)
        platformOptions.push('Integração');
    if (user.profile.enterprise.automation)
        platformOptions.push('Automação');

    const columns = [
        { field: 'uniqueid', headerName: 'Identificador', flex: 1, filterOperators: filterOperators },
        {
            field: 'date_time', type: 'dateTime', headerName: 'Data e hora', flex: 1, filterable: false,
            filterOperators: dateOperators,
            valueGetter: formatRowDateTime,
            renderCell: (params) => (
                <Box>
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'platform_entrypoint', headerName: 'Canal', flex: 0.3,
            valueOptions: platformOptions,
            filterOperators: choiceOperators,
            renderCell: (params) => (
                <Box>
                    {platformIcon(params.value)}
                </Box>
            ),
        },
        {
            field: 'bot_duration', type: 'number', headerName: 'Duração (ChatBot)', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (
                <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
            ),
        },
        {
            field: 'wait_duration', type: 'number', headerName: 'Duração (Fila)', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (
                <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
            ),
        },
        {
            field: 'queue_duration', type: 'number', headerName: 'Duração (Atendimento)', flex: 1,
            filterOperators: numericOperators,
            valueGetter: (params) => {
                return params.value - params.row.survey_duration
            },
            renderCell: (params) => (
                <Box>{new Date((params.value) * 1000).toISOString().substr(11, 8)}</Box>
            ),
        },
        {
            field: 'survey_duration', type: 'number', headerName: 'Duração (Pesquisa)', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (
                <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
            ),
        },
        {
            field: 'duration', type: 'number', headerName: 'Duração (Total)', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (
                <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
            ),
        },
        {
            field: 'status', type: 'singleSelect', headerName: 'Status', flex: 0.5,
            valueOptions: ['Atendida', 'ChatBot', 'Desistência'],
            filterOperators: choiceOperators,
        },
        {
            field: 'status_hangup', type: 'singleSelect', headerName: 'Desligamento', flex: 1, filterable: false, sortable: false,
            valueGetter: (params) => {
                if (params.row.status === 'Desistência')
                    return null
                if (params.row.tags) {
                    let tags = params.row.tags.split(',')
                    let filteredTags = tags.map(e=>e.trim()).filter(tag => tag.startsWith('sys_'))

                    if (filteredTags.includes('sys_timeout'))
                        return 'Desligada por “timeout”'
                    if (filteredTags.includes('sys_timeout_fds'))
                        return 'Desligada por “timeout” (fds)'
                    if (filteredTags.includes('sys_fora_hora'))
                        return 'Fora do horário de atendimento'
                }
                if (params.row.status === 'ChatBot' && !params.row.agent)
                    return 'Retida no “chatbot”'
                return 'Normal'
            },
        },
        {
            field: 'custom_field3_value', headerName: 'Finalizador', flex: 1,
            filterOperators: choiceOperators,
            valueOptions: ['Chamador', 'Recebedor']
        },
        {
            field: 'custom_field2_value', headerName: 'Tipo', flex: 1,
            filterOperators: choiceOperators,
            valueOptions: ['Comando', 'Sainte', 'Entrante'],
            renderCell: (params) => {
                if (params.row.leg != 0)
                    return params.row.CUSTOM_FIELD1_VALUE;
                return params.row.value;
            }
        },
        {
            field: 'queue_name', headerName: 'Fila', flex: 1, valueGetter: getQueueNameAndDesc,
            filterOperators: filterOperators
        },
        {
            field: 'messages', headerName: 'Mensagens', flex: 1,
            filterOperators: filterOperators,
            renderCell: (params) => (
                <Box>
                    Na coluna ações
                </Box>
            ),
        },
        {
            field: 'agent', headerName: 'Operador', flex: 1, valueGetter: getAgentNameAndCode,
            filterOperators: filterOperators,
        },
        {
            field: 'labels', headerName: 'Etiquetas', flex: 1,
            filterOperators: filterOperators, sortable: false,
            valueGetter: (params) => { return params.value ? params.value.map(each => each.name).join(', ') : '' },
            renderCell: (params) => (
                <>
                    {fillLabels(params.row.labels)}
                </>
            )
        },
        {
            field: 'contact_name', headerName: 'Nome do contato', flex: 1,
            filterOperators: filterOperators
        },
        {
            field: 'customer_code', headerName: 'Cód. Cliente', flex: 1,
            filterOperators: filterOperators
        },
        {
            field: 'origin', headerName: 'Origem', flex: 1,
            filterOperators: filterOperators
        },
        {
            field: 'dst_num', headerName: 'Destino', flex: 1,
            filterOperators: filterOperators
        },
        {
            field: 'survey_type', headerName: 'Tipo de pesquisa', flex: 1,
            valueOptions: ['CSAT', 'NPS', 'BUILDER'],
            filterOperators: choiceOperators
        },
        {
            field: 'survey_number_1', headerName: 'Resposta 1', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_number_2', headerName: 'Resposta 2', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_number_3', headerName: 'Resposta 3', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_number_4', headerName: 'Resposta 4', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_number_5', headerName: 'Resposta 5', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_number_6', headerName: 'Resposta 6', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_number_7', headerName: 'Resposta 7', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_number_8', headerName: 'Resposta 8', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_number_9', headerName: 'Resposta 9', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_number_10', headerName: 'Resposta 10', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (<SurveyCell type={params.row.survey_type}>{params.value}</SurveyCell>)
        },
        {
            field: 'survey_comment', headerName: 'Comentários', flex: 1,
            filterOperators: filterOperators,
            renderCell: (params) => (
                <Box className="survey-cell">{params.value}</Box>
            )
        },
        {
            field: 'tags', headerName: 'Tags', flex: 1,
            filterOperators: filterOperators,
            valueGetter: (params) => {
                if (params.value) {
                    let tags = params.value.split(',')
                    let filteredTags = tags.filter(tag => !tag.startsWith('sys_'))
                    return filteredTags.join(',')
                }
                return params.value
            },
            renderCell: (params) => {
                return (<>
                    {user.profile.enterprise.has_tags && params.value !== '' ?
                        <Tooltip title="Ver Tags" placement="top" disableInteractive>
                            <Button className="action-button"
                                onClick={async () => {
                                    const dialogData = await fetchTags(params.row.uniqueid.split(' ')[0])
                                    if (dialogData)
                                        setParent({
                                            activeDialog: (
                                                <TagsDialog
                                                    parent={params}
                                                    data={dialogData}
                                                    handleClose={() => setParent({ activeDialog: null })}
                                                />
                                            )
                                        })

                                }}
                            >
                                <LabelIcon />
                            </Button>
                        </Tooltip>
                        :
                        <Box>{params.value}</Box>
                    }</>)
            }
        },
        {
            field: 'cortex_duration', headerName: 'Duração (Cortex)', flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (
                <Box>{new Date(Number(params.value) * 1000).toISOString().substr(11, 8)}</Box>
            ),
        },
        {
            field: 'cortex_question_amount', headerName: 'Qtd. Perguntas (Cortex)', flex: 1,
            filterOperators: numericOperators
        },
        {
            field: 'cortex_answer_amount', headerName: 'Qtd. Respostas (Cortex)', flex: 1,
            filterOperators: numericOperators
        },
        {
            field: 'cortex_valid_answers', headerName: 'Qtd. Respostas Válidas (Cortex)', flex: 1,
            filterOperators: numericOperators
        },
        {
            field: 'cortex_kb', headerName: 'Base de conhecimento (Cortex)', flex: 1,
            filterOperators: filterOperators,
            valueGetter: (params) => {
                if (params.value != 'False') {
                    return params.value
                }
                return ''
            }
        },
        {
            field: 'total_msg', headerName: 'Mensagens (atendimento)', flex: 1,
            filterOperators: numericOperators
        },
        {
            field: 'total_msg_general', headerName: 'Mensagens', flex: 1,
            filterOperators: numericOperators
            
        },
        {
            field: 'average_response_time', 
            headerName: 'TMR', 
            flex: 1,
            filterOperators: numericOperators,
            renderCell: (params) => (
                params.value !== null ? (
                    <Box>{new Date(params.value * 1000).toISOString().substr(11, 8)}</Box>
                ) : (
                    <Box></Box>
                )
            ),
        },
        {
            field: 'actions', headerName: 'Ações', flex: 0.5, filterable: false, sortable: false,
            renderCell: (params) => {

                if (params.row.platform_entrypoint === 'INSTANTVOICE') {
                    return (<RenderVoiceActions {...params}/>)
                }

                return (
                    <Box className='report-action-buttons'>
                        <Tooltip title="Visualizar histórico de mensagens" placement="top" disableInteractive>
                            <Button className={params.value.split('_')[0] !== 'transfer'
                                ? 'action-button'
                                : 'action-button is-transfer'
                            }
                                onClick={async () => {
                                    const dialogData = await fetchHistoryMessages(params.value.split('_')[1].split(' ')[0])
                                    if (dialogData){
                                        if (dialogData.messages.length === 0){
                                            useNotification(
                                                'Atenção!',
                                                'Não há mensagens para exibir.',
                                                'info'
                                            )
                                            return
                                        }
                                        setParent({
                                            activeDialog: (
                                                <HistoryMessagesDialog open={true}
                                                    activeChat={dialogData}
                                                    uniqueId={dialogData.uniqueid}
                                                    nick={dialogData.nick}
                                                    lastDateTime={dialogData.time}
                                                    historyMessages={dialogData.messages}
                                                    platform={dialogData.platform}
                                                    accountcode={dialogData.accountcode}
                                                    isReport={true}
                                                    handleClose={() => setParent({ activeDialog: null })}
                                                />
                                            )
                                        })
                                    }
                                        

                                }}
                            >
                                <ChatIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Exportar histórico de mensagens" placement="top" disableInteractive>
                            <Button className="action-button"
                                href={"/api/api/enterprises/"
                                    + enterpriseId
                                    + "/reports/chat/detailed/download/"
                                    + params.row.uniqueid.split(' ')[0]
                                    + "/"
                                    + params.row.time
                                    + "/"
                                }
                            >
                                <ExportIcon />
                            </Button>
                        </Tooltip>
                    </Box>
                )
            },
        }
    ]



    return columns
}


const SurveyCell = ({ type, children }) => {
    return (<Box className="survey-cell">
        <Box className="answers-survey">
            <Box className={
                type === 'CSAT' && Number(children) >= 4 ? 'answer answer-green' :
                    type === 'CSAT' && Number(children) >= 3 ? 'answer answer-yellow' :
                        type === 'CSAT' && Number(children) < 3 ? 'answer answer-red' :
                            type === 'NPS' && Number(children) >= 9 ? 'answer answer-green' :
                                type === 'NPS' && Number(children) >= 7 ? 'answer answer-yellow' : 'answer answer-red'
            }>
                {children}
            </Box>
        </Box>
    </Box>)
}

const handleReportRow = (item, uniqueidAndHour) => {
    item.path = [item.uniqueid + '_' + item.time];
    item.actions = 'notTransfer_' + item.uniqueid + '_';
    uniqueidAndHour[item.uniqueid] = item.time;

    if (item.platform_entrypoint !== 'INSTANTVOICE' && item.status == 'Atendida' && !item.queue_name)
        item.status = 'ChatBot';
    if (item.status == 'Desistencia')
        item.status = 'Desistência';
    if (item.status == 'Nao Atendida')
        item.status = 'Não Atendida';

    if (item.tags){

    }

    item.origin = formatPhoneNumber(item.origin);
    item.dst_num = formatPhoneNumber(item.dst_num);

    // if (item.platform_entrypoint === 'INSTANTVOICE'){
    //     item.queue_name = this.state.voiceQueues[item.queue_name]
    // }

    if (item.survey) {
        item.survey_type = 'BUILDER'
        if (item.survey.type)
            item.survey_type = item.survey.type.toUpperCase()
        item.survey_numbers = item.survey.answers.filter(each => each.question !== 'comment')
        item.survey_comment = item.survey.answers.filter(each => each.question === 'comment')?.map(each => each.value)?.join(', ')

        item.survey_numbers.some(each => {
            item[`survey_number_${each.question}`] = each.value
        })

        let answers = [];
        item.survey.answers.forEach(answer => {
            answers.push(answer.value);
        });
        item.survey = `${item.survey_type}:${JSON.stringify(answers)}`;
    } else {
        item.survey = '';
    }

}

const platformIcon = (platform) => {
    switch (platform) {
        case 'INSTANTCHAT':
            return (
                <Tooltip title="Chat Web" placement="bottom" disableInteractive>
                    <InstantChatIcon className="instantchat" />
                </Tooltip>
            );
        case 'WHATSAPP':
            return (
                <Tooltip title="WhatsApp" placement="bottom" disableInteractive>
                    <WhatsAppIcon className="whatsapp" />
                </Tooltip>
            );
        case 'TELEGRAM':
            return (
                <Tooltip title="Telegram" placement="bottom" disableInteractive>
                    <TelegramIcon className="telegram" />
                </Tooltip>
            );
        case 'INSTAGRAM':
            return (
                <Tooltip title="Instagram" placement="bottom" disableInteractive>
                    <InstagramIcon className="instagram" />
                </Tooltip>
            );
        case 'MESSENGER':
            return (
                <Tooltip title="Messenger" placement="bottom" disableInteractive>
                    <FacebookIcon className="messenger" />
                </Tooltip>
            );
        case 'MSTEAMS':
            return (
                <Tooltip title="Microsoft Teams" placement="bottom" disableInteractive>
                    <MicrosoftIcon className="msteams" />
                </Tooltip>
            );
        case 'AUTOMATION':
            return (
                <Tooltip title="Automação" placement="bottom" disableInteractive>
                    <SmartToyIcon className="email" />
                </Tooltip>
            );
        case 'INTEGRATION':
            return (
                <Tooltip title="Integração" placement="bottom" disableInteractive>
                    <IntegrationInstructionsIcon className="integration" />
                </Tooltip>
            );
        case 'WHATSAPP_ATIVO':
            return (
                <Tooltip title="WhatsApp" placement="bottom" disableInteractive>
                    <WhatsAppIcon className="whatsapp" />
                </Tooltip>
            );
        case 'INSTANTVOICE':
            return (
                <Tooltip title="Voz" placement="bottom" disableInteractive>
                    <PhoneEnabledIcon />
                </Tooltip>
            );
        case 'EMAIL':
            return (
                <Tooltip title="Email" placement="bottom" disableInteractive>
                    <EmailIcon className="email" />
                </Tooltip>
            );
        case 'API':
            return (
                <Tooltip title="API" placement="bottom" disableInteractive>
                    <ApiIcon className="email" />
                </Tooltip>
            );
    }
}


const fillLabels = (labels) => {

    const filteredLabels = labels

    return (
        <Box className='wrapped-parent'>
            <Box
                className='wrapped-relevant'
            >
                <List
                    className={'special-inline-flex'}
                    sx={{ height: "100%" }}>
                    {filteredLabels?.map(label => (
                        <ListItem
                            disableGutters
                            className="label-item"
                            onClick={() => { }}
                            sx={{ background: `${label.color} !important` }}
                        >
                            <ListItemButton disableGutters sx={{ padding: "4px", overflow: "hidden", fontSize: "0.6rem", }}>
                                <span>{label.name}</span>
                            </ListItemButton>
                        </ ListItem >
                    ))}
                </List>
            </Box>
        </Box>)
}

const convertAudio = async (exportUrl, accountcode) => {

    const data = {
        file: exportUrl
    }

    const res = await api.post(`/api/convertaudio/`, data, { timeout: 0 })

    if (res.status == 200)
        return res.data
    console.error('Failed to convert data.')
    return ''
}

const handleAudioPlay = async (id, exportUrl, accountcode, uniqueid, state, setState) => {

    if (state.smallLoading)
        return

    if (state.playingAudio.audio != null) {
        state.playingAudio.audio.pause()
        state.playingAudio.audio.currentTime = 0;
    }


    setState({ smallLoading: true, playingAudio: { id: id, audio: null } })

    let audio = new Audio(exportUrl);
    audio.onended = (() => { setState({ playingAudio: { id: null, audio: null } }) })
    try {
        await audio.play();
        setState({ playingAudio: { id: id, audio: audio } })
    } catch (err) {
        useNotification(
            'Aguarde!',
            'O áudio está sendo preparado.',
            'info'
        )
        try {
            const res = await convertAudio(exportUrl, accountcode)
            audio = new Audio(res);
            audio.onended = (() => { setState({ playingAudio: { id: null, audio: null } }) })
            await audio.play()
            setState({ playingAudio: { id: id, audio: audio } })
        }
        catch (err) {
            console.error(err)
            setState({ playingAudio: { id: null, audio: null } })
            useNotification(
                'Ops...',
                'O arquivo de áudio não existe.',
                'danger'
            )
        }
    }

    setState({ smallLoading: false })

}

const RenderVoiceActions = (params) => {

    const [state, setState] = useReducer(lockedReducer, {
        playingAudio: {},
        smallLoading: false
    })

    const accountcode = user.profile.enterprise.accountcode;
    const uniqueid = params.row.uniqueid;
    const date = params.row.date.replaceAll('-', '');
    const destination = params.row.destination;
    const fileName = `${uniqueid}.WAV`;

    if (params.row.platform_entrypoint === 'INSTANTVOICE') {
        params.row.leg_type = params.row.custom_field1_value
        params.row.type = params.row.custom_field2_value
    }



    let type = params.row.type === 'Entrante'
        ? 'inbound'
        : 'outbound'
        ;

    if (params.row.leg !== 0)
        type = params.row.leg_type?.toLowerCase();


    let directory = `${accountcode}_${destination}`;
    // if (type === 'inbound' && params.row.platform_entrypoint !== 'INSTANTVOICE')
    //     // Directory name is different for inbound calls
    //     directory = directory.split('_').pop();

    //const exportUrl = `/export/${accountcode}/${type}/${date}/${directory}/${fileName}`;
    let exportUrl = ''

    let source = 'record'
    if (params.row.platform_entrypoint === 'INSTANTVOICE') {
        source = 'messages'
    }

    try {
        if (params.row[source])
            exportUrl = atob(params.row[source])
    } catch (e) {
        console.error(e)
        exportUrl = ''
    }


    if (params.row.status !== 'Atendida') {
        // Don't show actions if calls has not been picked up
        // Should it be shown?
        return (<></>);
    }

    return (
        <Box>

            {state.playingAudio?.id == params.row.id ?
                <>
                    {state.playingAudio.paused ?
                        <Tooltip title="Continuar" placement="top"
                            disableInteractive>
                            <Button className="action-button"
                                onClick={(event) => {
                                    state.playingAudio.audio.play()
                                    setState({ playingAudio: { ...state.playingAudio, paused: false } })
                                }}>
                                <PlayIcon sx={{ color: 'green' }} />
                            </Button>
                        </Tooltip>
                        :
                        <Tooltip title={state.smallLoading ? 'Carregando' : 'Pausar'} placement="top"
                            disableInteractive>
                            <Button className="action-button"
                                onClick={(event) => {
                                    if (state.smallLoading)
                                        return
                                    state.playingAudio.audio.pause()
                                    setState({ playingAudio: { ...state.playingAudio, paused: true } })
                                }}>
                                {state.smallLoading ? <AutorenewIcon className={'spinner'} /> : <PauseIcon />}
                            </Button>
                        </Tooltip>
                    }
                </>
                :
                <Tooltip title="Ouvir gravação" placement="top"
                    disableInteractive>
                    <Button className="action-button"
                        onClick={(event) => {
                            handleAudioPlay(
                                params.row.id,
                                exportUrl,
                                accountcode,
                                uniqueid,
                                state,
                                setState
                            );
                        }}>
                        <PlayIcon />
                    </Button>
                </Tooltip>
            }
            <Tooltip title="Baixar gravação" placement="top"
                disableInteractive>
                <Button className="action-button"
                    href={exportUrl}
                    download>
                    <ExportIcon />
                </Button>
            </Tooltip>
        </Box>
    )
}

export const formatRowDateTime = (params) => {
    let date = params.row.date;

    let dateParts = date.split('-');

    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];

    let time = params.row.time;
    let timeParts = time.split(':');

    let hours = timeParts[0];
    let minutes = timeParts[1];
    let seconds = timeParts[2];

    let newDate = new Date(year, month - 1, day, hours, minutes, seconds)

    return newDate.toLocaleString('pt-BR').replace(',', '');
}

const getQueueNameAndDesc = (params) => {
    if ('queue_data' in params.row) {
        const queue_name = params.row.queue_name;
        const queue_desc = params.row.queue_data.description;
        return `${queue_desc} (${queue_name})`
    }
    return params.row.queue_name;
}

const getAgentNameAndCode = (params) => {
    if ('agent_data' in params.row) {
        const agent_code = params.row.agent;
        const agent_name = params.row.agent_data.name;
        return `${agent_name} (${agent_code})`
    }
    return params.row.agent;
}


const CustomGridTreeDataGroupingCell = (props) => {
    const { id, field, rowNode } = props;
    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
        apiRef,
        gridFilteredDescendantCountLookupSelector,
    );

    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleKeyDown = (event) => {
        if (event.key === ' ') {
            event.stopPropagation();
        }
        if (isNavigationKey(event.key) && !event.shiftKey) {
            apiRef.current.publishEvent(GridEvents.cellNavigationKeyDown, props, event);
        }
    };

    const handleClick = (event) => {
        apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        apiRef.current.setCellFocus(id, field);
        event.stopPropagation();
    };

    return (
        <Box sx={{ ml: rowNode.depth * 4 }}>
            <div>
                {filteredDescendantCount == 1 &&
                    <Button
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        tabIndex={-1}
                        size="small"
                    >
                        Ver {filteredDescendantCount} transferência
                    </Button>
                }
                {filteredDescendantCount > 1 &&
                    <Button
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        tabIndex={-1}
                        size="small"
                    >
                        Ver {filteredDescendantCount} transferências
                    </Button>
                }
            </div>
        </Box>
    );
}