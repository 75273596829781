import React, { useEffect, useReducer } from "react";
import { Box, Alert } from "@mui/material";
import { getSession } from "../../../auth.js";
import api from "../../../api";
import Loading from "../../Loading/index.js";
import ListConversations from "./ListConversations/index.js";
import { lockedReducer } from '../../../utils/defaultReducer.js'
import './styles.css'
import ChatList from "./ChatList/index.js";
import ChatWindow from "./ChatWindow/index.js";
import AccountList from "./AccountList/index.js";
import ScoutSocketComponent, { treatEvents } from "./SocketComponent/index.js";

const getAccounts = async (enterprise_id, setState) => {

  setState({loading: true})
  const res = await api.get(
    `api/enterprises/${enterprise_id}/scout_accounts/`
  );
  if (res.status === 200) {
    setState({accounts:res.data, loading:false})
    return
  }
  setState({loading:false})
}

const MonitorPage = () => {

  const windowRef = {}

  const enterprise = getSession().profile.enterprise

  const [state, setState] = useReducer(lockedReducer, {
    chats: [],
    accounts: [],
    activeAccount: null,
    activeChat: null,
    loading: false,
    socket: null
  })

  useEffect(() => {
    getAccounts(enterprise.id, setState)
  }, []);

  window.setState = setState

  return (
    <>
      <Loading loading={state.loading} />
      <ScoutSocketComponent parent={state} setParent={setState} windowRef={windowRef} />
      {enterprise.platforms.scout ? <Box className="scout-wrapper">
        <Box className='scout-accountlist'><AccountList parent={state} setParent={setState} /></Box>
        <Box className='scout-chatwindow'><ChatWindow parent={state} setParent={setState} innerRef={windowRef} /></Box>
      </Box>
        :
        <Box className='scout-alert-padding'>
          <Alert severity="info">
            Entre em contato com seu gerente de contas para contratar o Kwik Scout - O canal de "Compliance"  e gravacao de WhatsApp do Kwik - <a href="https://wa.link/0bs73j">Clique aqui.</a>
          </Alert>
        </Box>
      }
    </>
  );
};

export default MonitorPage;
